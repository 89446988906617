import { apiClient, API_BASE_PATH, createHeaders } from './apiClient';
import { type RequestHeaders, type RequestPaginationParams } from '../types';

export const supplierService = {
  getAll: (headers: RequestHeaders, params?: RequestPaginationParams) =>
    apiClient.get(`${API_BASE_PATH}/suppliers`, {
      params,
      headers: createHeaders(headers),
    }),
};
