import {
  LineItemRow,
  TransactionDirection,
  type JournalForm,
  type CreateJournalEntriesRequestBody,
  type JournalEntryRowRequestBody,
} from '../../../types';
import { FORM_FIELDS_ID, FORM_TYPES } from '../constants/constants';

export const formatDate = (date: Date) => {
  return date.toLocaleString('sv').split(' ')[0];
};

export const formatRequestBody = (
  selectedFormType: string,
  formData: JournalForm
): CreateJournalEntriesRequestBody => {
  let journalEntryRows = [] as JournalEntryRowRequestBody[];
  const isAdvancedForm = selectedFormType === FORM_TYPES.ADVANCED;

  for (let item of Object.values(formData.lineItems)) {
    const formattedRow = Object.values(item.rows).map((row) => {
      const { transactionDirection } = row;
      const journalEntryRowDimensions =
        getDimensionsByFormType(selectedFormType, row) || [];
      return {
        transactionDirection: transactionDirection.toUpperCase(),
        accountInformation: {
          accountId: row.accountInformation.id.objectId,
          accountType: row.accountInformation.accountType,
        },
        responsibilityCenter: row.responsibilityCenter,
        branch: row.branch,
        description: row.rowDescription,
        productQuantity: isAdvancedForm ? row.productQuantity : undefined,
        productId: isAdvancedForm ? row.product.id : undefined,
        totalEmission:
          transactionDirection === TransactionDirection.DEBIT
            ? row.totalEmissionDebit
            : row.totalEmissionCredit,
        totalStdDev:
          transactionDirection === TransactionDirection.DEBIT
            ? row.totalStdDevDebit
            : row.totalStdDevCredit,
        journalEntryRowDimensions,
        emissionScope: row.emissionScope,
        dataQualityIndex: row.dataQualityIndex,
      };
    });
    journalEntryRows = [...journalEntryRows, ...formattedRow];
  }

  const advancedFormDetails = isAdvancedForm
    ? {
        transactionDetails: {
          currency: formData.financialCurrency,
          amount: formData.totalFinancialAmount,
        },
        supplierId: formData.supplier.id.objectId,
        supplierInvoiceNumber: formData.supplierInvoiceNumber,
      }
    : {};

  const request = {
    transactionDate: formData.transactionDate,
    transactionType: formData.transactionType,
    description: formData.journalDescription,
    generalLedgerId: formData.subsidiary,
    journalEntryRows,
    ...advancedFormDetails,
  };

  return request;
};

const getDimensionsByFormType = (formType: string, row: LineItemRow) => {
  if (formType === FORM_TYPES.SIMPLIFIED) {
    return [
      {
        dimensionName: FORM_FIELDS_ID.PROJECT_CODE,
        dimensionValue: row.projectCode,
      },
    ];
  } else if (formType === FORM_TYPES.ADVANCED) {
    return [
      {
        dimensionName: FORM_FIELDS_ID.PRODUCT_QUANTITY_UNIT,
        dimensionValue: row.productQuantityUnit,
      },
      {
        dimensionName: FORM_FIELDS_ID.SRC_OF_EMISSION_FACTOR_AND_STD_DEV,
        dimensionValue: row.srcOfEmissionFactorAndStdDev,
      },
      {
        dimensionName: FORM_FIELDS_ID.EMISSION_FACTOR,
        dimensionValue: row.emissionFactor,
      },
      {
        dimensionName: FORM_FIELDS_ID.STD_DEV_EMISSION_FACTOR,
        dimensionValue: row.stdDevEmissionFactor,
      },
      {
        dimensionName: FORM_FIELDS_ID.PROJECT_CODE,
        dimensionValue: row.projectCode,
      },
    ];
  }
};
