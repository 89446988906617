import { createBrowserRouter } from 'react-router-dom';

import { Home } from '../routes/Home';
import { CreateJournal } from '../routes/CreateJournal';
import { ErrorPage } from '../routes/ErrorPage';

export const routes = {
  HOME: '/',
  CREATE: '/create',
  ADMIN: '/admin',
};

export type CreatRouteLoader = {
  formType?: string
};

export const router = createBrowserRouter([
  {
    path: routes.HOME,
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: `${routes.CREATE}/:formType`,
    element: <CreateJournal />,
    errorElement: <ErrorPage />,
    loader: ({ params }): CreatRouteLoader => ({ formType: params.formType }),
  },
]);
