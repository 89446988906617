export const defaultColorTheme = {
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#222e3a',
    },
    neutral: {
      main: '#b1aeb7',
      contrastText: '#fff',
    },
  },
};

export default defaultColorTheme

