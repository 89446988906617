import { ChangeEvent, useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../context/AuthContext';
import { api } from '../../api';
import { TransactionEntry } from '../../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { columns } from './columns';

export const TransactionEntriesTable = () => {
  const { getToken } = useContext(AuthContext);

  const [transactionEntries, setTransactionEntries] =
    useState<TransactionEntry[]>([]);
  const [totalEntries, setTotalEntries] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getToken().then((accessToken: string) => {
      if (accessToken) {
        const params = {
          page: page,
          pageSize: rowsPerPage,
          sortBy: 'createdAt',
          sortDirection: 'DESC',
        };

        api.journalService.getAll({ accessToken }, params).then((result) => {
          setTransactionEntries(result.data.items);
          setTotalEntries(result.data.totalItems);
        });
      }
    });
  }, [getToken, page, rowsPerPage]);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(({ name, width }) => (
                <TableCell
                  key={name}
                  width={width}
                  style={{ verticalAlign: 'top', overflow: 'hidden' }}
                >
                  <Typography fontSize="small" fontWeight="600">
                    {name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionEntries.length > 0 ? (
              transactionEntries?.map(
                ({
                  subsidiaryName,
                  transactionDate,
                  journalEntryRowId,
                  journalEntryDisplayId,
                  accountNumber,
                  emissionScope,
                  transactionDirection,
                  responsibilityCenter,
                  branch,
                  dataQualityIndex,
                  totalEmission,
                  totalStdDev,
                  description,
                }) => {
                  return (
                    <TableRow key={journalEntryRowId}>
                      <TableCell>{subsidiaryName}</TableCell>
                      <TableCell>{journalEntryDisplayId}</TableCell>
                      <TableCell>{transactionDate}</TableCell>
                      <TableCell>{accountNumber}</TableCell>
                      <TableCell>{emissionScope}</TableCell>
                      <TableCell>{branch}</TableCell>
                      <TableCell>{responsibilityCenter}</TableCell>
                      <TableCell>{dataQualityIndex}</TableCell>
                      <TableCell>
                        {transactionDirection === 'DEBIT' ? totalEmission : '—'}
                      </TableCell>
                      <TableCell>
                        {transactionDirection === 'CREDIT'
                          ? totalEmission
                          : '—'}
                      </TableCell>
                      <TableCell>
                        {transactionDirection === 'DEBIT' ? totalStdDev : '—'}
                      </TableCell>
                      <TableCell>
                        {transactionDirection === 'CREDIT' ? totalStdDev : '—'}
                      </TableCell>
                      <TableCell>{!description ? '—' : description}</TableCell>
                      <TableCell>{journalEntryRowId}</TableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow>
                <TableCell colSpan={13}>
                  <Typography align="center">No journals available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalEntries}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </>
  );
};
