import { TransactionDirection, JournalForm, LineItemRow } from '../../../types';
import { FORM_FIELDS_ID } from '../constants/constants';

export function checkJournalBalance(formData: JournalForm) {
  const { lineItems } = formData;
  const itemsData = Object.values(lineItems);

  for (const item of itemsData) {
    const { rows } = item;
    const rowsData = Object.values(rows);
    const { debitSums, creditSums } = getEmissionSums(rowsData);
    const isEmissionBalanced =
      debitSums >= 0 && creditSums >= 0 && debitSums === creditSums;
    if (!isEmissionBalanced) return false; // If an item doesn't balance, we stop the check loop
  }

  return true;
}

export function checkFinancialBalance(formData: JournalForm) {
  const { totalFinancialAmount, lineItems } = formData;
  const journalAmount = Number(totalFinancialAmount);
  const itemsData = Object.values(lineItems);

  const financialSum = sumByField(FORM_FIELDS_ID.FINANCIAL_AMOUNT, itemsData);
  const roundFinancialSum = Math.round(financialSum * 100) / 100; // requires rounding due to floating point math

  const isFinancialSumBalanced =
    financialSum > 0 &&
    journalAmount > 0 &&
    roundFinancialSum === journalAmount;

  return isFinancialSumBalanced;
}

export function sumByField(field: string, array: any[]) {
  if (array.length > 0) {
    const allValues = array.map((row) => Number(row[field]));
    const sum = allValues.reduce((prev: number, curr: number) => prev + curr);
    return sum;
  }
  return 0;
}

export function getEmissionSums(rows: LineItemRow[]) {
  const rowsData = Object.values(rows);

  const debitRows = rowsData.filter((row) => {
    return row.transactionDirection === TransactionDirection.DEBIT;
  });
  const debitSums = sumByField(FORM_FIELDS_ID.TOTAL_EMISSION_DEBIT, debitRows);

  const creditRows = rowsData.filter((row) => {
    return row.transactionDirection === TransactionDirection.CREDIT;
  });
  const creditSums = sumByField(
    FORM_FIELDS_ID.TOTAL_EMISSION_CREDIT,
    creditRows
  );

  return { debitSums, creditSums };
}
