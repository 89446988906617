const { REACT_APP_OAUTH2_CLIENT_ID, REACT_APP_OAUTH2_TENANT_ID, REACT_APP_OAUTH2_REDIRECT_URL, REACT_APP_OAUTH2_SCOPE } = process.env;

export const msalConfig = {
  auth: {
    clientId: `${REACT_APP_OAUTH2_CLIENT_ID}`,
    authority: `https://login.microsoftonline.com/${REACT_APP_OAUTH2_TENANT_ID}`,
    redirectUri: `${REACT_APP_OAUTH2_REDIRECT_URL}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [`${REACT_APP_OAUTH2_SCOPE}`],
};
