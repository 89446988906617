export const FORM_TYPES = {
  SIMPLIFIED: 'simplified',
  ADVANCED: 'advanced',
};

export const FORM_FIELDS_ID = {
  TRANSACTION_DATE: 'transactionDate',
  FINANCIAL_CURRENCY: 'financialCurrency',
  TRANSACTION_TYPE: 'transactionType',
  TOTAL_FINANCIAL_AMOUNT: 'totalFinancialAmount',
  SUPPLIER: 'supplier',
  SUPPLIER_INVOICE_NUMBER: 'supplierInvoiceNumber',
  FINANCIAL_AMOUNT: 'financialAmount',
  JOURNAL_DESCRIPTION: 'journalDescription',
  SUBSIDIARY: 'subsidiary',
  TRANSACTION_DIRECTION: 'transactionDirection',
  ACCOUNT_INFORMATION: 'accountInformation',
  BRANCH: 'branch',
  RESPONSIBILITY_CENTER: 'responsibilityCenter',
  PROJECT_CODE: 'projectCode',
  EMISSION_SCOPE: 'emissionScope',
  SRC_OF_EMISSION_FACTOR_AND_STD_DEV: 'srcOfEmissionFactorAndStdDev',
  PRODUCT: 'product',
  PRODUCT_QUANTITY: 'productQuantity',
  PRODUCT_QUANTITY_UNIT: 'productQuantityUnit',
  EMISSION_FACTOR: 'emissionFactor',
  STD_DEV_EMISSION_FACTOR: 'stdDevEmissionFactor',
  DATA_QUALITY_INDEX: 'dataQualityIndex',
  TOTAL_EMISSION_DEBIT: 'totalEmissionDebit',
  TOTAL_STD_DEV_DEBIT: 'totalStdDevDebit',
  TOTAL_EMISSION_CREDIT: 'totalEmissionCredit',
  TOTAL_STD_DEV_CREDIT: 'totalStdDevCredit',
  ROW_DESCRIPTION: 'rowDescription',
  DELETE: 'delete',
};

export const FORM_FIELDS_LABEL = {
  TRANSACTION_DATE: 'Transaction Date',
  FINANCIAL_CURRENCY: 'Financial Currency',
  TRANSACTION_TYPE: 'Transaction Type',
  TOTAL_FINANCIAL_AMOUNT: 'Total Financial Value',
  SUPPLIER: 'Supplier',
  SUPPLIER_INVOICE_NUMBER: 'Supplier invoice number',
  FINANCIAL_AMOUNT: 'Financial value',
  JOURNAL_DESCRIPTION: 'Description (optional)',
  SUBSIDIARY: 'Subsidiary',
  TRANSACTION_DIRECTION: 'Debit / Credit',
  ACCOUNT_INFORMATION: 'GHG Account',
  BRANCH: 'Branch',
  RESPONSIBILITY_CENTER: 'Responsibility Center',
  PROJECT_CODE: 'Project Code',
  EMISSION_SCOPE: 'Emission Scope',
  SRC_OF_EMISSION_FACTOR_AND_STD_DEV:
    'Source of Emission Factor and Standard Deviation',
  PRODUCT: 'Article',
  PRODUCT_QUANTITY: 'Article Quantity',
  PRODUCT_QUANTITY_UNIT: 'Article Quantity Unit',
  EMISSION_FACTOR: 'Emission Factor',
  DATA_QUALITY_INDEX: 'Data Quality Index',
  STD_DEV_EMISSION_FACTOR: 'Standard Deviation of Emission Factor',
  TOTAL_EMISSION_DEBIT: 'Total Emissions Debit',
  TOTAL_STD_DEV_DEBIT: 'Total Standard Deviation Debit',
  TOTAL_EMISSION_CREDIT: 'Total Emissions Credit',
  TOTAL_STD_DEV_CREDIT: 'Total Standard Deviation Credit',
  ROW_DESCRIPTION: 'Description (optional)',
};

export const SERVICE_NAMES = {
  JOURNAL_SERVICE: 'journalService',
  ACCOUNT_SERVICE: 'accountService',
  SUPPLIER_SERVICE: 'supplierService',
  SUPPLIER_PRODUCT_SERVICE: 'supplierProductService',
};
