import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { type SelectOptionType, type SelectInputValue } from '../../../types';

type Props = {
  id: string;
  onChange: (event: SelectChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  selectOptions?: SelectOptionType[];
  inputValue: SelectInputValue;
  required?: boolean;
  disabled?: boolean;
  width?: string;
  autoFocus?: boolean;
};

export const SelectField = (props: Props) => {
  const {
    id,
    onChange,
    onBlur,
    selectOptions,
    inputValue,
    required = true,
    disabled,
    width,
    autoFocus,
  } = props;

  return (
    <FormControl fullWidth>
      <InputLabel shrink={false} sx={{ color: '#aaa' }}>
        {!inputValue && 'Select...'}
      </InputLabel>
      <Select
        name={id}
        value={inputValue}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        sx={{ width }}
        autoFocus={autoFocus}
      >
        {selectOptions &&
          selectOptions.map(({ key, value, display }) => (
            <MenuItem key={key} value={value}>
              {display}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
