import { Grid } from "@mui/material";
import { SignInButton } from "../components/SignInButton/SignInButton";

export const LoginPage = () => (
  <Grid
    container
    alignContent="center"
    justifyContent="center"
    style={{ minHeight: '70vh' }}
    padding={6}
  >
    <SignInButton />
  </Grid>
);
