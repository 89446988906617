import { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { JournalFormContext } from '../../context/JournalFormContext';

import { sumByField } from '../../providers/JournalFormProvider/utils/checkJournalBalance';
import { roundDecimals } from '../../providers/JournalFormProvider/utils/transactionCalculations';

import { JournalForm } from '../../types';
import { FORM_FIELDS_ID } from '../../providers/JournalFormProvider/constants/constants';

export const FinancialBalanceCard = () => {
  const { formData } = useContext(JournalFormContext);
  const { totalFinancialAmount, lineItems, financialCurrency } =
    formData as JournalForm;

  const lineItemsData = Object.values(lineItems);
  const lineItemSum = roundDecimals(
    sumByField(FORM_FIELDS_ID.FINANCIAL_AMOUNT, lineItemsData)
  );

  const totalAmountDisplayValue = roundDecimals(totalFinancialAmount);

  return (
    <Stack direction="row">
      <Stack
        spacing={1}
        padding={2}
        border={1}
        borderRadius={1}
        borderColor="lightgray"
      >
        <Typography variant="h6">Financial balance</Typography>
        <Stack>
          <Stack direction="row">
            <Box width={'200px'}>
              <Typography>Journal total</Typography>
            </Box>
            <Box width={'200px'}>
              <Typography>Line items total</Typography>
            </Box>
          </Stack>
          <Stack direction="row">
            <Box width={'200px'}>
              <Typography>
                {totalAmountDisplayValue} {financialCurrency}
              </Typography>
            </Box>
            <Box width={'200px'}>
              <Typography>
                {lineItemSum} {financialCurrency}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
