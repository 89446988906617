import { useContext } from 'react';
import { Typography, TableRow, TableCell } from '@mui/material';

import { JournalFormContext } from '../../context/JournalFormContext';

import { FORM_TYPES } from '../../providers/JournalFormProvider/constants/constants';
import { getEmissionSums } from '../../providers/JournalFormProvider/utils/checkJournalBalance';
import { roundDecimals } from '../../providers/JournalFormProvider/utils/transactionCalculations';

type Props = {
  itemId: string;
};

export const EmissionSumRow = ({ itemId }: Props) => {
  const { formData, formType } = useContext(JournalFormContext);

  const lineItems = formData.lineItems;
  const item = lineItems[itemId];
  const rowsData = Object.values(item.rows);

  const emissionSums = getEmissionSums(rowsData);

  return (
    <TableRow>
      <TableCell
        colSpan={formType === FORM_TYPES.ADVANCED ? 12 : 6}
      ></TableCell>
      <TableCell align="right" colSpan={2}>
        <Typography>Emissions balance:</Typography>
      </TableCell>
      <TableCell sx={{ paddingX: '22px' }}>
        <Typography>{roundDecimals(emissionSums.debitSums)}</Typography>
      </TableCell>
      <TableCell colSpan={4} sx={{ paddingX: '22px' }}>
        <Typography>{roundDecimals(emissionSums.creditSums)}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default EmissionSumRow;
