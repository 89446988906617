import { ReactNode } from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import { Typography, Stack, Link, Divider } from '@mui/material';
import { NavigationMenu } from '../NavigationMenu/NavigationMenu';

import { routes } from '../../configs/routerConfig';

type HeaderLinkProps = {
  children: ReactNode;
};

export const Header = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <header>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingX={6}
        paddingY={4}
      >
        <Stack
          direction="row"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <HeaderLink>
            <img src="/logo.png" alt="Logo" width={150} />
          </HeaderLink>
          <HeaderLink>
            <Typography variant="h5" textAlign="center">
              Emission Accounting Portal
            </Typography>
          </HeaderLink>
        </Stack>
        {isAuthenticated && <NavigationMenu />}
      </Stack>
    </header>
  );
};

const HeaderLink = ({ children }: HeaderLinkProps) => (
  <Link href={routes.HOME} underline="none" color="inherit">
    {children}
  </Link>
);
