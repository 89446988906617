import { Context, createContext } from 'react';
import {
  FormFields,
  InputValue,
  JournalForm,
  OptionType,
  RequestParams,
  ServiceType,
  SubmissionError,
} from '../types';

type JournalFormContextType = {
  formData: JournalForm;
  isSubmissionAllowed: boolean;
  formFields?: FormFields;
  formType: any;
  submissionError: SubmissionError;
  clearForm: () => void;
  handleBlur: (
    inputField: string,
    value: InputValue,
    itemId?: string,
    rowId?: string
  ) => void;
  handleSubmit: () => Promise<unknown>;
  handleAddLineItem: () => void;
  handleAddRow: (itemId: string) => void;
  handleDeleteLineItem: (itemId: string) => void;
  handleDeleteRow: (itemId: string, rowId: string) => void;
  handleSearch: (
    service: ServiceType,
    filterParams: RequestParams,
    sortBy: string
  ) => Promise<OptionType[]>;
};

export const JournalFormContext: Context<JournalFormContextType> = createContext(
  {} as JournalFormContextType
);
