import { Product, GhgAccount, Supplier, InputValue } from './';

export interface Object {
  [index: string]: string | number | Object | Object[] | undefined | InputValue;
}

export interface SelectOptionType {
  key: string | number;
  value: string | number;
  display: string | number;
}

export type SelectOptionsByEnumType =
  | typeof TransactionDirection
  | typeof SourceOfStdAndEmissionFactor
  | typeof EmissionScope
  | typeof DataQualityIndex;

export interface StringSelectOptionType extends SelectOptionType {
  value: string;
}

export type SelectOptions = {
  emissionScopeOptions: SelectOptionType[];
  currencyOptions: SelectOptionType[];
  transactionTypeOptions: SelectOptionType[];
  transactionDirectionOptions: SelectOptionType[];
  responsibilityCenterOptions: StringSelectOptionType[];
  branchOptions: StringSelectOptionType[];
  sourceOfStdAndEmissionFactorOptions: SelectOptionType[];
  generalLedgerOptions: SelectOptionType[];
  dataQualityIndexOptions: SelectOptionType[];
};

export type GeneralLedgerOption = {
  id: string;
  name: string;
};

export type SearchFieldSourceType = {
  serviceName: string;
  display: string;
  searchFrequency: number;
  filterBy?: string;
};

export enum TransactionDirection {
  DEBIT = 'Debit',
  CREDIT = 'Credit',
}

export enum SourceOfStdAndEmissionFactor {
  ACCOUNT = 'Account',
  ARTICLE = 'Article',
  CALCULATED = 'Calculated by H2GS',
  SUPPLIER = 'Provided by supplier',
}

export enum EmissionScope {
  SCOPE_1 = '1',
  SCOPE_2 = '2',
  SCOPE_3 = '3',
}

export enum DataQualityIndex {
  DATA_QUALITY_INDEX_1 = 1,
  DATA_QUALITY_INDEX_2,
  DATA_QUALITY_INDEX_3,
  DATA_QUALITY_INDEX_4,
  DATA_QUALITY_INDEX_5
}

export interface JournalForm extends Object {
  transactionDate: string;
  transactionType: string;
  supplier: Supplier;
  supplierInvoiceNumber: string;
  financialCurrency: string;
  totalFinancialAmount: number;
  subsidiary: string;
  journalDescription?: string;
  lineItems: LineItems;
}

export interface LineItems extends Object {
  [itemId: string]: LineItem;
}

export interface LineItem extends Object {
  financialAmount: number;
  rows: Rows;
}

export interface Rows extends Object {
  [rowId: string]: LineItemRow;
}

export interface LineItemRow extends Object {
  transactionDirection: TransactionDirection;
  accountInformation: GhgAccount;
  responsibilityCenter: string;
  branch: string;
  projectCode: string;
  emissionScope: EmissionScope;
  emissionFactor: number | string;  // string because of initial form value
  srcOfEmissionFactorAndStdDev: SourceOfStdAndEmissionFactor;
  product: Product;
  productQuantity: number | string; // string because of initial form value
  productQuantityUnit: string;
  stdDevEmissionFactor: number | string;  // string because of initial form value
  dataQualityIndex: DataQualityIndex | string;  // string because of initial form value
  totalEmissionDebit: number;
  totalEmissionCredit: number;
  totalStdDevDebit: number;
  totalStdDevCredit: number;
  rowDescription?: string;
}

export interface FormFields {
  headerFields: Field[];
  itemFields?: Field[];
  rowFields: Field[];
}

export type Field = {
  id: string;
  name?: string;
  label: string;
  type?: string;
  width?: string;
  min?: number;
  max?: number;
  step?: number | 'any';
  disabled?: boolean;
  required?: boolean;
  selectOpions?: SelectOptionType[];
  source?: SearchFieldSourceType;
};

export type SubmissionError = {
  showError?: boolean;
  message?: string;
};
