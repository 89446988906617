import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type MissingPermisionDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export const MissingPermissionDialog = ({
  open,
  handleClose,
}: MissingPermisionDialogProps) => (
  <Dialog open={open}>
    <DialogTitle>Access denied</DialogTitle>
    <DialogContent>
      <DialogContentText>
        You don't have permission to use this service.
        <br />
        Please contact the Digital Team for more information on how to get
        access.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Logout</Button>
    </DialogActions>
  </Dialog>
);
