import { v4 as uuidv4 } from 'uuid';
import {
  EmissionScope,
  SourceOfStdAndEmissionFactor,
  TransactionDirection,
} from '../../../types';

export const createNewRow = () => {
  const id = uuidv4();
  return {
    [id]: {
      transactionDirection: Object.values(TransactionDirection)[0],
      accountInformation: {
        id: { objectId: '' },
        accountNumber: '',
        accountType: '',
        emissionDetails: {},
      },
      responsibilityCenter: '',
      branch: '',
      projectCode: '',
      emissionScope: EmissionScope.SCOPE_3,
      srcOfEmissionFactorAndStdDev: Object.values(
        SourceOfStdAndEmissionFactor
      )[0],
      product: {
        id: '',
        supplierProductName: '',
        productQuantityUnit: '',
        emissionDetails: {},
      },
      productQuantity: '',
      productQuantityUnit: '',
      emissionFactor: '',
      stdDevEmissionFactor: '',
      dataQualityIndex: '',
      totalEmissionDebit: 0,
      totalEmissionCredit: 0,
      totalStdDevDebit: 0,
      totalStdDevCredit: 0,
      rowDescription: '',
    },
  };
};

export const createNewLineItem = () => {
  const id = uuidv4();
  return {
    [id]: {
      financialAmount: 0,
      rows: { ...createNewRow() },
    },
  };
};

export const initialCurrencyOptions = [
  {
    key: 'SEK',
    value: 'SEK',
    display: 'SEK',
  },
];
