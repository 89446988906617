import { useContext, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  InputLabel,
  Stack,
  Button,
  Box,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

import { JournalFormContext } from '../../context/JournalFormContext';

import LineItemRow from './LineItemTableRow';
import LineItemTableHead from './LineItemTableHead';
import { ConfirmModal } from '../ui/ConfirmModal/ConfirmModal';

import {
  type LineItem as LineItemType,
  type Field,
  type InputValue,
} from '../../types';
import { FormField } from '../ui/FormField/FormField';
import EmissionSumRow from './EmissionSumRow';
import { FORM_TYPES } from '../../providers/JournalFormProvider/constants/constants';

type Props = {
  item: LineItemType;
  itemId: string;
  index: number;
};

export const LineItem = ({ item, itemId, index }: Props) => {
  const {
    formFields,
    handleBlur,
    handleSearch,
    handleAddRow,
    handleDeleteLineItem,
    formType,
  } = useContext(JournalFormContext);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  return (
    <Stack spacing={2}>
      {formFields && formFields.itemFields ? (
        <>
          {/** HEADLINE */}
          <Typography variant="h6">{`Line item ${index + 1}`}</Typography>

          {/** COMMON ITEM FIELDS */}
          {formFields.itemFields.map((field: Field) => {
            const { id, label } = field;
            const fieldId = id.split(':')[1];
            const value = item[fieldId] as InputValue;

            const formFieldProps = {
              ...field,
              value,
              handleBlur,
              itemId,
              handleSearch,
            };
            return (
              <Box key={id}>
                <InputLabel htmlFor={id} sx={{ whiteSpace: 'pre-wrap' }}>
                  {label}
                </InputLabel>
                <FormField {...formFieldProps} />
              </Box>
            );
          })}
        </>
      ) : null}

      {/** ROWS */}
      <TableContainer>
        <Table>
          <TableHead>
            <LineItemTableHead />
          </TableHead>
          <TableBody>
            {Object.entries(item.rows).map(([id, row]) => (
              <LineItemRow key={id} row={row} itemId={itemId} rowId={id} />
            ))}
            <EmissionSumRow itemId={itemId} />
          </TableBody>
        </Table>
      </TableContainer>

      {/** ITEM CTA BUTTONS */}
      <Stack direction="row" spacing={2} marginY={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddCircleOutlineIcon />}
          disableElevation
          onClick={() => handleAddRow(itemId)}
        >
          Add row
        </Button>
        {formType === FORM_TYPES.ADVANCED && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<DeleteIcon />}
            disableElevation
            onClick={() => setConfirmDeleteOpen(true)}
          >
            Remove line item
          </Button>
        )}
      </Stack>
      <ConfirmModal
        type="delete"
        open={confirmDeleteOpen}
        handleConfirm={() => {
          handleDeleteLineItem(itemId);
        }}
        handleClose={() => setConfirmDeleteOpen(false)}
      />
    </Stack>
  );
};

export default LineItem;
