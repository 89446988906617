import { Typography } from '@mui/material';

import { PageLayout } from '../components/PageLayout/PageLayout';
import JournalEntryForm from '../components/JournalEntryForm/JournalEntryForm';
import { JournalFormProvider } from '../providers/JournalFormProvider/JournalFormProvider';
import { ErrorToaster } from '../components/ui/ErrorToaster/ErrorToaster';

export const CreateJournal = () => (
  <JournalFormProvider>
    <PageLayout>
      <Typography variant="h4" textAlign="left">
        Create journal entry
      </Typography>
      <JournalEntryForm />
    </PageLayout>
    <ErrorToaster />
  </JournalFormProvider>
);
