import { type Api } from '../types/serviceTypes';
import { accountService } from './accountService';
import { journalService } from './journalService';
import { supplierService } from './supplierService';
import { supplierProductService } from './supplierProductService';
import { branchService } from './branchService';
import { transactionTypeService } from './transactionTypeService';
import { responsibilityCenterService } from './responsibilityCenterService';
import { generalLedgerService } from './generalLedgerService';
import { currencyService } from './currencyService';

export const api = {
  journalService,
  accountService,
  supplierService,
  supplierProductService,
  branchService,
  transactionTypeService,
  responsibilityCenterService,
  generalLedgerService,
  currencyService,
} as Api;
