import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Box, Button } from '@mui/material';

import { JournalFormContext } from '../../context/JournalFormContext';

import LineItem from './LineItem';
import { FormHeader } from './FormHeader';
import { type LineItem as LineItemType } from '../../types';

import { routes } from '../../configs/routerConfig';
import { FinancialBalanceCard } from '../BalanceCard/FinancialBalanceCard';
import {
  ConfirmModal,
  type ConfirmationType,
} from '../ui/ConfirmModal/ConfirmModal';
import { FORM_TYPES } from '../../providers/JournalFormProvider/constants/constants';

type ConfirmOptions = {
  type: ConfirmationType;
  handleConfirm: () => void;
};

export const JournalEntryForm = () => {
  const {
    formFields,
    formData,
    isSubmissionAllowed,
    handleSubmit,
    handleAddLineItem,
    formType,
    clearForm,
  } = useContext(JournalFormContext);

  const navigate = useNavigate();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState<ConfirmOptions>({
    type: 'submit',
    handleConfirm: () => {},
  });

  const handleSubmitForm = () => {
    handleSubmit()
      .then(() => {
        navigate(routes.HOME);
      })
      .catch((error: string) => console.log(`ERROR: ${error}`));
  };

  const handleCancelForm = () => {
    navigate(routes.HOME);
    clearForm();
  };

  return (
    <>
      {formFields ? (
        <Stack
          component="form"
          onSubmit={(event) => {
            event.preventDefault();
            setConfirmOpen(true);
            setConfirmOptions({
              type: 'submit',
              handleConfirm: () => handleSubmitForm(),
            });
          }}
          autoComplete="off"
          spacing={8}
        >
          {/* FORM HEADER */}
          <FormHeader />

          {/* ITEMS & ROWS */}
          {Object.entries(formData.lineItems).map(([itemId, item], index) => {
            return (
              <LineItem
                key={itemId}
                index={index}
                item={item as LineItemType}
                itemId={itemId}
              />
            );
          })}
          <Stack spacing={6}>
            {formType === FORM_TYPES.ADVANCED && (
              <Box alignSelf="flex-start">
                <Stack direction="row">
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={handleAddLineItem}
                  >
                    Add line item
                  </Button>
                </Stack>
              </Box>
            )}
            {/* FORM FOOTER */}
            <Stack spacing={2}>
              {formType === FORM_TYPES.ADVANCED && <FinancialBalanceCard />}
              {/* FORM CTA BUTTONS */}
              <Stack direction="row" spacing={2}>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={!isSubmissionAllowed}
                >
                  Book Journal
                </Button>
                <Button
                  size="large"
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setConfirmOpen(true);
                    setConfirmOptions({
                      type: 'cancel',
                      handleConfirm: () => handleCancelForm(),
                    });
                  }}
                  disableElevation
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
      <ConfirmModal
        type={confirmOptions?.type}
        open={confirmOpen}
        handleConfirm={confirmOptions?.handleConfirm}
        handleClose={() => setConfirmOpen(false)}
      />
    </>
  );
};

export default JournalEntryForm;
