import { type ChangeEvent } from 'react';
import { FormControl, TextField as MuiTextField } from '@mui/material';
import { type InputValue } from '../../../types';

type Props = {
  id: string;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  inputValue: InputValue;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number | 'any';
  width?: string;
  placeholder?: string;
  autoFocus?: boolean;
};

export const TextField = (props: Props) => {
  const {
    id,
    type,
    onChange,
    onBlur,
    inputValue,
    required = true,
    disabled,
    min,
    max,
    step,
    width,
    placeholder,
    autoFocus,
  } = props;

  return (
    <FormControl fullWidth>
      <MuiTextField
        id={id}
        type={type}
        variant="outlined"
        multiline={type === 'multiline'}
        onChange={onChange}
        onBlur={onBlur}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        value={inputValue}
        InputProps={{ inputProps: { min, max, step } }}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        sx={{ width }}
        autoFocus={autoFocus}
      />
    </FormControl>
  );
};
