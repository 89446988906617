import { Box, Typography, Stack } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import { PageLayout } from '../components/PageLayout/PageLayout';

type RouteErrorType = {
  status: string;
  statusText: string;
  message: string;
};

export const ErrorPage = () => {
  const error = useRouteError() as RouteErrorType;

  return (
    <PageLayout>
      <Stack spacing={3}>
        <Typography variant="h2">Oops!</Typography>
        <Box>
          <Typography>Sorry, an unexpected error has occurred.</Typography>
          <Typography fontStyle="italic">
            {error.status} {error.statusText || error.message}
          </Typography>
        </Box>
      </Stack>
    </PageLayout>
  );
};
