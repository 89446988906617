import {
  TableRow,
  TableCell,
  InputLabel,
  Box,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { JournalFormContext } from '../../context/JournalFormContext';
import { Field } from '../../types';

export const LineItemTableHead = () => {
  const { formFields } = useContext(JournalFormContext);

  return (
    <TableRow>
      {formFields &&
        formFields.rowFields.map((field: Field) => {
          const { id, label, width, type } = field;
          return (
            <TableCell
              key={id}
              sx={{ verticalAlign: 'bottom', padding: '0px 8px 8px 8px' }}
            >
              {type === 'button' ? (
                <Box
                  sx={{
                    width,
                  }}
                ></Box>
              ) : (
                <InputLabel
                  htmlFor={id}
                  sx={{
                    whiteSpace: 'pre-wrap',
                    minWidth: '100px',
                    width,
                  }}
                >
                  <Typography fontSize="small" fontWeight="600">
                    {label}
                  </Typography>
                </InputLabel>
              )}
            </TableCell>
          );
        })}
    </TableRow>
  );
};

export default LineItemTableHead;
