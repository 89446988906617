import { useNavigate } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { PageLayout } from '../components/PageLayout/PageLayout';
import { TransactionEntriesTable } from '../components/TransactionEntriesTable/TransactionEntriesTable';
import { routes } from '../configs/routerConfig';

import { FORM_TYPES } from '../providers/JournalFormProvider/constants/constants';

export const Home = () => {
  const navigate = useNavigate();

  const handleCreateNew = (formType: string) => {
    navigate(`${routes.CREATE}/${formType}`);
  };

  return (
    <PageLayout>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Typography variant="h4" textAlign="left">
          Transaction entries
        </Typography>
        <Box alignSelf="flex-end">
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              startIcon={<AddIcon />}
              onClick={() => {
                handleCreateNew(FORM_TYPES.ADVANCED);
              }}
            >
              Add advanced
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              startIcon={<AddIcon />}
              onClick={() => {
                handleCreateNew(FORM_TYPES.SIMPLIFIED);
              }}
            >
              Add simplified
            </Button>
          </Stack>
        </Box>
      </Stack>
      <TransactionEntriesTable />
    </PageLayout>
  );
};
