import { Grid, InputLabel } from '@mui/material';
import { useContext } from 'react';
import { JournalFormContext } from '../../context/JournalFormContext';
import { FORM_TYPES } from '../../providers/JournalFormProvider/constants/constants';
import { type Field, type InputValue } from '../../types';

import { FormField } from '../ui/FormField/FormField';

export const FormHeader = () => {
  const { formType, formData, handleBlur, handleSearch, formFields } =
    useContext(JournalFormContext);

  return (
    <Grid container columnSpacing={6} rowSpacing={2}>
      {formFields &&
        formFields.headerFields.map((field: Field, index: number) => {
          const { id, label } = field;
          const headerId = id.split(':')[1];
          const formFieldProps = {
            ...field,
            value: formData[headerId] as InputValue,
            handleBlur,
            handleSearch,
          };

          return (
            <Grid
              item
              xs={formType === FORM_TYPES.ADVANCED ? 4 : 3}
              key={`header-${index}`}
            >
              <InputLabel htmlFor={id}>{label}</InputLabel>
              <FormField {...formFieldProps} />
            </Grid>
          );
        })}
    </Grid>
  );
};
