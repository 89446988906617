import { apiClient, API_BASE_PATH, createHeaders } from './apiClient';
import {
  type CreateJournalEntriesRequestBody,
  type RequestHeaders,
  type RequestPaginationParams,
} from '../types';

export const journalService = {
  getAll: (headers: RequestHeaders, params?: RequestPaginationParams) =>
    apiClient.get(`${API_BASE_PATH}/journals`, {
      params,
      headers: createHeaders(headers),
    }),

  getById: (id: string, headers: RequestHeaders) =>
    apiClient.get(`${API_BASE_PATH}/journals/${id}`, {
      headers: createHeaders(headers),
    }),

  create: (body: CreateJournalEntriesRequestBody, headers: RequestHeaders) =>
    apiClient.post(`${API_BASE_PATH}/journals`, body, {
      headers: createHeaders(headers),
    }),
};
