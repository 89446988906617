import { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material';

const content: ConfirmModalContent = {
  submit: {
    title: 'Do you want to book this journal?',
    buttonLabels: {
      abort: 'Cancel',
      confirm: 'Book',
    },
  },
  cancel: {
    title: 'Do you want to cancel?',
    body: 'If you cancel the process, your data will be lost.',
    buttonLabels: {
      abort: 'No',
      confirm: 'Yes',
    },
  },
  delete: {
    title: 'Are you sure that you want to delete this line item?',
    buttonLabels: {
      abort: 'Cancel',
      confirm: 'Delete',
    },
  },
};

export type ConfirmationType = 'submit' | 'cancel' | 'delete';

type ConfirmModalContent = {
  submit: ModalContent;
  cancel: ModalContent;
  delete: ModalContent;
};

type ModalContent = {
  title: string;
  body?: string | ReactNode;
  buttonLabels: {
    abort: string;
    confirm: string;
  };
};

type Props = {
  type: ConfirmationType;
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const ConfirmModal = (props: Props) => {
  const { type, open, handleClose, handleConfirm } = props;

  const { title, body, buttonLabels } = content[type] || {};

  const onConfirm = () => {
    handleConfirm();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      {body && <DialogContent>{body}</DialogContent>}
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Stack direction="row" spacing={2} paddingBottom={1}>
          <Button onClick={handleClose} variant="outlined">
            {buttonLabels?.abort}
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            color={type === 'submit' ? 'primary' : 'error'}
            disableElevation
          >
            {buttonLabels?.confirm}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
