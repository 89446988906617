import { useContext } from 'react';
import { TableRow, TableCell, IconButton, Box } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { JournalFormContext } from '../../context/JournalFormContext';

import {
  TransactionDirection,
  Field,
  type LineItemRow as LineItemRowType,
} from '../../types';
import { FormField } from '../ui/FormField/FormField';
import { roundDecimals } from '../../providers/JournalFormProvider/utils/transactionCalculations';

type Props = {
  row: LineItemRowType;
  rowId: string;
  itemId: string;
};

export const LineItemRow = ({ row, rowId, itemId }: Props) => {
  const { formData, formFields, handleBlur, handleDeleteRow, handleSearch } =
    useContext(JournalFormContext);

  const selectedTransactionDirection =
    formData.lineItems[itemId].rows[rowId].transactionDirection;

  return (
    <TableRow>
      {formFields &&
        formFields.rowFields.map((field: Field) => {
          const { id, type, disabled } = field;
          const fieldId = id.split(':')[1];
          const value = row[fieldId] as string;

          const isDisabledCreditRow =
            fieldId.toLowerCase().includes('debit') &&
            selectedTransactionDirection === TransactionDirection.CREDIT;

          const isDisabledDebitRow =
            fieldId.toLowerCase().includes('credit') &&
            selectedTransactionDirection === TransactionDirection.DEBIT;

          const formFieldProps = {
            ...field,
            value,
            disabled: isDisabledCreditRow || isDisabledDebitRow,
            handleBlur,
            handleSearch,
            itemId,
            rowId,
          };

          return (
            <TableCell
              key={id}
              sx={{
                padding: '8px',
              }}
            >
              {type === 'button' ? (
                <IconButton
                  color="primary"
                  onClick={() => handleDeleteRow(itemId, rowId)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              ) : disabled ? (
                <Box fontSize="medium" paddingX={2}>
                  {typeof value === 'number' ? roundDecimals(value) : value}
                </Box>
              ) : (
                <FormField {...formFieldProps} />
              )}
            </TableCell>
          );
        })}
    </TableRow>
  );
};

export default LineItemRow;
