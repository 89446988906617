import { RouterProvider } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import { createTheme, ThemeProvider } from '@mui/material';

import { AuthProvider } from './providers/AuthProvider';
import { Header } from './components/Header/Header';
import { LoginPage } from './routes/LoginPage';

import { msalConfig } from './configs/authConfig';
import colorThemeConfig from './configs/colorThemeConfig';

import { router } from './configs/routerConfig';

import './App.css';

const msalInstance = new PublicClientApplication(msalConfig);
const theme = createTheme(colorThemeConfig);

function App() {
  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Header />
            <AuthenticatedTemplate>
              <RouterProvider router={router} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
              <LoginPage />
            </UnauthenticatedTemplate>
          </AuthProvider>
        </ThemeProvider>
      </MsalProvider>
    </div>
  );
}

export default App;
