import {
  Button,
  Divider,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { useContext, useState } from 'react';
import { routes } from '../../configs/routerConfig';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { AuthContext } from '../../context/AuthContext';

export const NavigationMenu = () => {
  const { handleSignOut, hasAdminAccess } = useContext(AuthContext);

  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuElement(event.currentTarget);
  };

  const handleClose = () => {
    setMenuElement(null);
  };

  return (
    <>
      <Button
        color="primary"
        size="large"
        onClick={(event) => handleClick(event)}
      >
        <MenuIcon />
      </Button>
      <Menu
        anchorEl={menuElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!menuElement}
        onClose={handleClose}
      >
        <MenuItem disabled={!hasAdminAccess}>
          <Link href={routes.ADMIN} underline="none" color="inherit">
            Admin Portal
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
