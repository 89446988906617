import { Alert, Snackbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { JournalFormContext } from '../../../context/JournalFormContext';

export const ErrorToaster = () => {
  const { submissionError } = useContext(JournalFormContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!submissionError?.showError);
  }, [submissionError]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {submissionError ? submissionError.message : 'An unexpected error happened.'}
      </Alert>
    </Snackbar>
  );
};
