import {
  TransactionDirection,
  LineItemRow,
  SourceOfStdAndEmissionFactor,
} from '../../../types';
import { FORM_FIELDS_ID } from '../constants/constants';

type CalculationProps = {
  srcOfEmissionFactorAndStdDev: SourceOfStdAndEmissionFactor;
  transactionDirection: TransactionDirection;
  financialAmount: number;
  productQuantity: number | string;
  emissionFactor: number | string;
  stdDevEmissionFactor: number | string;
};

export const shouldUpdateEmissionFactors = (field: string) => {
  const emissionFactorControllers = [
    FORM_FIELDS_ID.EMISSION_SCOPE,
    FORM_FIELDS_ID.ACCOUNT_INFORMATION,
    FORM_FIELDS_ID.PRODUCT,
    FORM_FIELDS_ID.SRC_OF_EMISSION_FACTOR_AND_STD_DEV,
  ];
  return emissionFactorControllers.includes(field);
};

export const shouldUpdateTotalEmissions = (field: string) => {
  const calculationControllers = [
    FORM_FIELDS_ID.TRANSACTION_DIRECTION,
    FORM_FIELDS_ID.ACCOUNT_INFORMATION,
    FORM_FIELDS_ID.EMISSION_SCOPE,
    FORM_FIELDS_ID.SRC_OF_EMISSION_FACTOR_AND_STD_DEV,
    FORM_FIELDS_ID.EMISSION_FACTOR,
    FORM_FIELDS_ID.PRODUCT,
    FORM_FIELDS_ID.PRODUCT_QUANTITY,
    FORM_FIELDS_ID.STD_DEV_EMISSION_FACTOR,
  ];

  return calculationControllers.includes(field);
};

export const calculateTotalEmissions = ({
  srcOfEmissionFactorAndStdDev,
  transactionDirection,
  financialAmount,
  productQuantity = 0,
  emissionFactor = 0,
  stdDevEmissionFactor = 0,
}: CalculationProps) => {
  const aggregator =
    srcOfEmissionFactorAndStdDev === SourceOfStdAndEmissionFactor.ACCOUNT
      ? financialAmount
      : (productQuantity as number);

  const totalEmission = aggregator * (emissionFactor as number);
  const totalStdDev = aggregator * (stdDevEmissionFactor as number);

  const isDebitRow = transactionDirection === TransactionDirection.DEBIT;
  const isCreditRow = transactionDirection === TransactionDirection.CREDIT;

  return {
    totalEmissionDebit: isDebitRow ? totalEmission : 0,
    totalEmissionCredit: isCreditRow ? totalEmission : 0,
    totalStdDevDebit: isDebitRow ? totalStdDev : 0,
    totalStdDevCredit: isCreditRow ? totalStdDev : 0,
  };
};

export const getEmissionFactors = (row: LineItemRow) => {
  const selectedScope = row[FORM_FIELDS_ID.EMISSION_SCOPE] as number;
  const factors =
    row.srcOfEmissionFactorAndStdDev === SourceOfStdAndEmissionFactor.ACCOUNT
      ? row.accountInformation?.emissionDetails
      : row.product?.emissionDetails;

  const doesFactorsExist = factors && Object.keys(factors).length > 0;

  const standarDeviation =
    doesFactorsExist && factors[selectedScope]?.standardDeviation;
  const emissionFactor =
    doesFactorsExist && factors[selectedScope]?.emissionFactor;
  const dataQualityIndex =
    doesFactorsExist && factors[selectedScope]?.dataQualityIndex;

  return {
    stdDevEmissionFactor:
      standarDeviation || standarDeviation === 0 ? standarDeviation : '', // additional check because 0 evaluates as falsy, but we want it as truthy
    emissionFactor:
      emissionFactor || emissionFactor === 0 ? emissionFactor : '',
    dataQualityIndex: dataQualityIndex || '', // dataQualityIndex can not be 0
  };
};

export const roundDecimals = (value: number) => {
  return Math.round(value * 10000) / 10000;
};
