import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';

import { AuthContext } from '../context/AuthContext';

import { MissingPermissionDialog } from '../components/MissingPermissionDialog/MissingPermissionDialog';

import { loginRequest } from '../configs/authConfig';
import { routes } from '../configs/routerConfig';

type AuthProviderProps = {
  children: ReactNode;
};

const ALLOWED_SECURITY_CONTEXT = 'staff';
const ALLOWED_ROLE = 'StaffRole_Accountant';
const ADMIN_ACCESS = 'StaffRole_Controller';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isMissingPermission, setIsMissingPermission] =
    useState<boolean>(false);
  const [hasAdminAccess, setHasAdminAccess] = useState<boolean>(false);
  const [activeAccount, setActiveAccount] = useState<AccountInfo>();

  const { instance: msalInstance, accounts } = useMsal();

  const getToken = useCallback(() => {
    return new Promise((resolve) => {
      msalInstance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          const { accessToken } = response;
          resolve(accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            return msalInstance.acquireTokenRedirect(loginRequest);
          }
        });
    });
  }, [accounts, msalInstance]);

  const handleSignOut = () => {
    const logoutRequest = {
      account: activeAccount,
      postLogoutRedirectUri: routes.HOME,
    };
    msalInstance.logoutRedirect(logoutRequest);
    setIsMissingPermission(false);
  };

  useEffect(() => {
    getToken();
  }, [getToken]);

  useEffect(() => {
    const currentAccount = accounts && accounts[0];
    setActiveAccount(currentAccount);
  }, [accounts]);

  useEffect(() => {
    if (activeAccount?.idTokenClaims) {
      const { roles, digital_identifier, security_context } =
        activeAccount?.idTokenClaims;

      if (
        !digital_identifier ||
        (security_context as string)?.toLowerCase() !==
          ALLOWED_SECURITY_CONTEXT ||
        !roles?.includes(ALLOWED_ROLE)
      ) {
        setIsMissingPermission(true);
      } else {
        setIsMissingPermission(false);
      }
      setHasAdminAccess(roles?.includes(ADMIN_ACCESS) || false);
    }
  }, [activeAccount]);

  return (
    <AuthContext.Provider
      value={{
        getToken,
        handleSignOut,
        hasAdminAccess,
      }}
    >
      <MissingPermissionDialog
        open={isMissingPermission}
        handleClose={handleSignOut}
      />
      {children}
    </AuthContext.Provider>
  );
};
