import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useLoaderData } from 'react-router-dom';

import { formatRequestBody, formatDate } from './utils/formatData';
import {
  checkFinancialBalance,
  checkJournalBalance,
} from './utils/checkJournalBalance';
import { JournalFormContext } from '../../context/JournalFormContext';
import { AuthContext } from '../../context/AuthContext';

import {
  calculateTotalEmissions,
  shouldUpdateTotalEmissions,
  getEmissionFactors,
  shouldUpdateEmissionFactors,
} from './utils/transactionCalculations';
import {
  createNewLineItem,
  createNewRow,
  initialCurrencyOptions,
} from './constants/initialFormData';

import { useForm } from '../../hooks/useForm';

import { api } from '../../api';
import { FORM_FIELDS_ID, FORM_TYPES } from './constants/constants';

import {
  type JournalForm,
  type ServiceType,
  type RequestParams,
  type InputValue,
  type SubmissionError,
  type OptionType,
} from '../../types';

type Props = {
  children: ReactNode;
  initialValues?: JournalForm;
};

export const JournalFormProvider = ({
  children,
  initialValues = {
    transactionDate: formatDate(new Date()),
    transactionType: '',
    supplier: { id: { objectId: '' }, supplierDisplayName: '' },
    supplierInvoiceNumber: '',
    financialCurrency: initialCurrencyOptions[0].value,
    totalFinancialAmount: 0,
    subsidiary: '',
    journalDescription: '',
    lineItems: { ...createNewLineItem() },
  },
}: Props) => {
  const { getToken } = useContext(AuthContext);
  const { formType } = useLoaderData() as any;
  const { formFields } = useForm();

  const [formData, setFormData] = useState<JournalForm>(initialValues);

  const [isSubmissionAllowed, setIsSubmissionAllowed] = useState(false);
  const [submissionError, setSubmissionError] = useState<SubmissionError>({});

  useEffect(() => {
    /** Checks if journal balances */
    const isJournalBalanced = checkJournalBalance(formData);
    const isFinaciallyBalanced = checkFinancialBalance(formData);
    if (formType === FORM_TYPES.ADVANCED) {
      setIsSubmissionAllowed(isJournalBalanced && isFinaciallyBalanced);
    } else if (formType === FORM_TYPES.SIMPLIFIED) {
      setIsSubmissionAllowed(isJournalBalanced);
    }
  }, [formType, formData]);

  const handleBlur = (
    inputField: string,
    value: InputValue,
    itemId?: string,
    rowId?: string
  ) => {
    const [position, dataField] = inputField.split(':');
    switch (position) {
      case 'header':
        setFormData({ ...formData, [dataField]: value });
        break;
      case 'item':
        if (itemId !== undefined) {
          const updated = updateItem(dataField, value, itemId);
          setFormData({
            ...formData,
            lineItems: { ...updated },
          });
        }
        break;
      case 'row':
        if (itemId !== undefined && rowId !== undefined) {
          const updated = updateRow(dataField, value, itemId, rowId);
          setFormData({
            ...formData,
            lineItems: { ...updated },
          });
        }
        break;
      default:
        setFormData({ ...formData, [dataField]: value });
        break;
    }
  };

  const updateItem = (field: string, value: InputValue, itemId: string) => {
    const lineItems = { ...formData.lineItems };
    const item = { ...lineItems[itemId] };
    const rows = { ...item.rows };

    let updatedItem = {
      ...item,
      [field]: value,
    };

    /** Calculates total emissions */
    Object.entries(rows).forEach(([rowId, row]) => {
      const calculatedFields = calculateTotalEmissions({
        ...row,
        financialAmount: updatedItem.financialAmount,
      });

      rows[rowId] = { ...row, ...calculatedFields };
    });

    updatedItem = {
      ...updatedItem,
      rows,
    };

    lineItems[itemId] = updatedItem;

    return lineItems;
  };

  const updateRow = (
    field: string,
    value: InputValue,
    itemId: string,
    rowId: string
  ) => {
    const lineItems = { ...formData.lineItems };
    const item = { ...lineItems[itemId] };
    const row = { ...item.rows[rowId] };

    let updatedRow = {
      ...row,
      [field]: value,
    };

    /** Autopopulate product quantity unit if it's available for the chosen product */
    if (field === FORM_FIELDS_ID.PRODUCT) {
      updatedRow = {
        ...updatedRow,
        productQuantityUnit: updatedRow.product?.productQuantityUnit || '',
      };
    }

    /** Updates emission factors of chosen source */
    if (shouldUpdateEmissionFactors(field)) {
      const emissionFactors = getEmissionFactors(updatedRow);
      updatedRow = {
        ...updatedRow,
        ...emissionFactors,
      };
    }

    /** Calculates total emissions */
    if (shouldUpdateTotalEmissions(field)) {
      const calculatedEmissions = calculateTotalEmissions({
        ...updatedRow,
        financialAmount: item.financialAmount,
      });

      updatedRow = {
        ...updatedRow,
        ...calculatedEmissions,
      };
    }

    item.rows[rowId] = updatedRow;
    return lineItems;
  };

  const handleSubmit = () => {
    return new Promise((resolve, reject) => {
      getToken().then((accessToken: string) => {
        if (accessToken) {
          if (isSubmissionAllowed && formType) {
            api.journalService
              .create(formatRequestBody(formType, formData), { accessToken })
              .then((response) => {
                setFormData(initialValues);
                resolve(response.statusText);
              })
              .catch((error) => {
                const errorMessage = 'An unexpected error happened';
                setSubmissionError({
                  showError: true,
                  message: errorMessage,
                });
                reject(`${errorMessage}, ${error}`);
              });
          } else {
            const errorMessage = "Invalid form can't be submitted";
            setSubmissionError({
              showError: true,
              message: errorMessage,
            });
            reject(errorMessage);
          }
        }
      });
    });
  };

  const handleAddLineItem = () => {
    const newLineItem = createNewLineItem();
    setFormData({
      ...formData,
      lineItems: {
        ...formData.lineItems,
        ...newLineItem,
      },
    });
  };

  const handleAddRow = (itemId: string) => {
    const lineItems = { ...formData.lineItems };
    const item = { ...lineItems[itemId] };
    const rows = { ...item.rows };

    const newRow = createNewRow();
    const updatedItem = {
      ...item,
      rows: { ...rows, ...newRow },
    };

    lineItems[itemId] = updatedItem;

    setFormData({
      ...formData,
      lineItems: { ...lineItems },
    });
  };

  const handleDeleteLineItem = (itemId: string) => {
    const lineItems = { ...formData.lineItems };

    delete lineItems[itemId];

    setFormData({
      ...formData,
      lineItems: { ...lineItems },
    });
  };

  const handleDeleteRow = (itemId: string, rowId: string) => {
    const lineItems = { ...formData.lineItems };
    const item = { ...lineItems[itemId] };
    const rows = { ...item.rows };

    delete rows[rowId];

    const updatedItem = {
      ...item,
      rows,
    };
    lineItems[itemId] = updatedItem;

    setFormData({
      ...formData,
      lineItems: { ...lineItems },
    });
  };

  const handleSearch = useCallback(
    (service: ServiceType, filterParams: RequestParams, sortBy: string) => {
      const currency = formData.financialCurrency;
      const params = {
        sortBy,
        sortDirection: 'ASC',
        ...filterParams,
        currency,
      };
      return new Promise<OptionType[]>((resolve, reject) => {
        getToken().then((accessToken: string) => {
          if (accessToken) {
            api[service]
              .getAll({ accessToken }, params)
              .then((result) => {
                resolve(result.data.items);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve([]);
          }
        });
      });
    },
    [formData.financialCurrency, getToken]
  );

  const clearForm = () => {
    setSubmissionError({});
    setFormData(initialValues);
  };

  return (
    <JournalFormContext.Provider
      value={{
        formData,
        isSubmissionAllowed,
        formFields,
        formType,
        submissionError,
        clearForm,
        handleBlur,
        handleSubmit,
        handleAddLineItem,
        handleAddRow,
        handleDeleteLineItem,
        handleDeleteRow,
        handleSearch,
      }}
    >
      {children}
    </JournalFormContext.Provider>
  );
};
