import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';

import { api } from '../api';

import {
  Branch,
  DataQualityIndex,
  EmissionScope,
  FormFields,
  GeneralLedgerOption,
  ResponsibilityCenter,
  SelectOptionsByEnumType,
  SelectOptionType,
  SourceOfStdAndEmissionFactor,
  StringSelectOptionType,
  TransactionDirection,
  TransactionType,
} from '../types';
import {
  getAdvancedFormFields,
  getSimplifiedFormFields,
} from '../components/JournalEntryForm/formFields';
import { useLoaderData } from 'react-router';
import { FORM_TYPES } from '../providers/JournalFormProvider/constants/constants';
import { initialCurrencyOptions } from '../providers/JournalFormProvider/constants/initialFormData';

const getSelectOptionsByStringEnum = (
  enumType: SelectOptionsByEnumType
): SelectOptionType[] => {
  return Object.entries(enumType).map((entry) => {
    const [key, value] = entry;

    return {
      key,
      value,
      display: value,
    };
  });
};

const getSelectOptionsByNumericEnum = (
  enumType: SelectOptionsByEnumType
): SelectOptionType[] => {
  const numericValues = Object.values(enumType).filter(
    (value) => typeof value === 'number'
  );
  return numericValues.map((value) => {
    return {
      key: value,
      value,
      display: value,
    };
  });
};

const formatResultsToOptions = (
  options:
    | Branch[]
    | TransactionType[]
    | ResponsibilityCenter[]
    | GeneralLedgerOption[]
) => {
  const formatted = options.map((option) => {
    const { id, name } = option;
    return {
      key: id,
      value: id,
      display: name,
    };
  });

  const sorted = formatted.sort((option1, option2) =>
    option1.display < option2.display ? -1 : 1
  );
  return sorted;
};

export const useForm = () => {
  const { formType } = useLoaderData() as any;
  const { getToken } = useContext(AuthContext);

  const [formFields, setFormFields] = useState<FormFields>();

  const [branchOptions, setBranchOptions] = useState<StringSelectOptionType[]>(
    []
  );
  const [transactionTypeOptions, setTransactionTypeOptions] = useState<
    StringSelectOptionType[]
  >([]);
  const [responsibilityCenterOptions, setResponsibilityCenterOptions] =
    useState<StringSelectOptionType[]>([]);
  const [generalLedgerOptions, setGeneralLedgerOptions] = useState<
    StringSelectOptionType[]
  >([]);
  const [currencyOptions, setCurrencyOptions] = useState<
    StringSelectOptionType[]
  >(initialCurrencyOptions);

  useEffect(() => {
    /** Fetch select field options */
    getToken().then((accessToken: string) => {
      if (accessToken) {
        api.branchService.getAll({ accessToken }).then((result) => {
          const items = result.data.items || [];
          const formattedOptions = formatResultsToOptions(items);
          setBranchOptions(formattedOptions);
        });
        api.transactionTypeService.getAll({ accessToken }).then((result) => {
          const items = result.data.items || [];
          const formattedOptions = formatResultsToOptions(items);
          setTransactionTypeOptions(formattedOptions);
        });
        api.responsibilityCenterService
          .getAll({ accessToken })
          .then((result) => {
            const items = result.data.items || [];
            const formattedOptions = formatResultsToOptions(items);
            setResponsibilityCenterOptions(formattedOptions);
          });
        api.generalLedgerService.getAll({ accessToken }).then((result) => {
          const items = result.data.items || [];
          const mappedItems = items.map(({ id, subsidiaryId }) => ({
            id,
            name: subsidiaryId,
          }));
          const formattedOptions = formatResultsToOptions(mappedItems);
          setGeneralLedgerOptions(formattedOptions);
        });
        api.currencyService.getAll({ accessToken }).then((result) => {
          const items = result.data.items || [];
          const mappedItems = items.map((currency) => ({
            id: currency,
            name: currency,
          }));
          const formattedOptions = formatResultsToOptions(mappedItems);
          setCurrencyOptions(formattedOptions);
        });
      }
    });
  }, [getToken]);

  const getSelectOptions = useCallback(() => {
    return {
      branchOptions,
      transactionTypeOptions,
      responsibilityCenterOptions,
      generalLedgerOptions,
      sourceOfStdAndEmissionFactorOptions: getSelectOptionsByStringEnum(
        SourceOfStdAndEmissionFactor
      ),
      emissionScopeOptions: getSelectOptionsByStringEnum(EmissionScope),
      currencyOptions,
      transactionDirectionOptions: getSelectOptionsByStringEnum(TransactionDirection),
      dataQualityIndexOptions: getSelectOptionsByNumericEnum(DataQualityIndex),
    };
  }, [
    branchOptions,
    transactionTypeOptions,
    responsibilityCenterOptions,
    generalLedgerOptions,
    currencyOptions,
  ]);

  useEffect(() => {
    const selectOptions = getSelectOptions();
    if (formType === FORM_TYPES.SIMPLIFIED) {
      const simplifiedFormFields = getSimplifiedFormFields(selectOptions);
      setFormFields(simplifiedFormFields);
    } else if (formType === FORM_TYPES.ADVANCED) {
      const advancedFormFields = getAdvancedFormFields(selectOptions);
      setFormFields(advancedFormFields);
    }
  }, [formType, setFormFields, getSelectOptions]);

  return {
    formFields,
  };
};
