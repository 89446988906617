import {
  FORM_FIELDS_ID,
  FORM_FIELDS_LABEL,
  SERVICE_NAMES,
} from '../../providers/JournalFormProvider/constants/constants';
import { type FormFields, type SelectOptions } from '../../types';

export const getAdvancedFormFields = (options: SelectOptions) =>
  ({
    headerFields: [
      {
        id: `header:${FORM_FIELDS_ID.TRANSACTION_DATE}`,
        label: `${FORM_FIELDS_LABEL.TRANSACTION_DATE}`,
        type: 'date',
      },
      {
        id: `header:${FORM_FIELDS_ID.SUPPLIER}`,
        label: `${FORM_FIELDS_LABEL.SUPPLIER}`,
        type: 'autoComplete',
        source: {
          serviceName: `${SERVICE_NAMES.SUPPLIER_SERVICE}`,
          display: 'supplierDisplayName',
          searchFrequency: 3,
          filterBy: 'supplierDisplayNameContains',
        },
      },
      {
        id: `header:${FORM_FIELDS_ID.FINANCIAL_CURRENCY}`,
        name: `header:${FORM_FIELDS_ID.FINANCIAL_CURRENCY}`,
        label: `${FORM_FIELDS_LABEL.FINANCIAL_CURRENCY}`,
        selectOptions: options.currencyOptions,
      },
      {
        id: `header:${FORM_FIELDS_ID.TRANSACTION_TYPE}`,
        name: `header:${FORM_FIELDS_ID.TRANSACTION_TYPE}`,
        label: `${FORM_FIELDS_LABEL.TRANSACTION_TYPE}`,
        selectOptions: options.transactionTypeOptions,
      },
      {
        id: `header:${FORM_FIELDS_ID.SUPPLIER_INVOICE_NUMBER}`,
        label: `${FORM_FIELDS_LABEL.SUPPLIER_INVOICE_NUMBER}`,
        placeholder: 'e.g. 000001',
      },
      {
        id: `header:${FORM_FIELDS_ID.TOTAL_FINANCIAL_AMOUNT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_FINANCIAL_AMOUNT}`,
        type: 'number',
        min: 0,
        step: 0.01,
      },
      {
        id: `header:${FORM_FIELDS_ID.SUBSIDIARY}`,
        label: `${FORM_FIELDS_LABEL.SUBSIDIARY}`,
        selectOptions: options.generalLedgerOptions,
      },
      {
        id: `header:${FORM_FIELDS_ID.JOURNAL_DESCRIPTION}`,
        label: `${FORM_FIELDS_LABEL.JOURNAL_DESCRIPTION}`,
        required: false,
      },
    ],
    itemFields: [
      {
        id: `item:${FORM_FIELDS_ID.FINANCIAL_AMOUNT}`,
        label: `${FORM_FIELDS_LABEL.FINANCIAL_AMOUNT}`,
        type: 'number',
        width: '150px',
        step: 0.01,
      },
    ],
    rowFields: [
      {
        id: `row:${FORM_FIELDS_ID.DELETE}`,
        type: 'button',
        width: '25px',
      },
      {
        id: `row:${FORM_FIELDS_ID.TRANSACTION_DIRECTION}`,
        name: `row:${FORM_FIELDS_ID.TRANSACTION_DIRECTION}`,
        label: `${FORM_FIELDS_LABEL.TRANSACTION_DIRECTION}`,
        selectOptions: options.transactionDirectionOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.ACCOUNT_INFORMATION}`,
        name: `row:${FORM_FIELDS_ID.ACCOUNT_INFORMATION}`,
        label: `${FORM_FIELDS_LABEL.ACCOUNT_INFORMATION}`,
        type: 'autoComplete',
        source: {
          serviceName: `${SERVICE_NAMES.ACCOUNT_SERVICE}`,
          display: 'accountNumber',
          searchFrequency: 1,
          filterBy: 'accountNumberStartsWith',
        },
        width: '130px',
      },
      {
        id: `row:${FORM_FIELDS_ID.BRANCH}`,
        name: `row:${FORM_FIELDS_ID.BRANCH}`,
        label: `${FORM_FIELDS_LABEL.BRANCH}`,
        width: '160px',
        selectOptions: options.branchOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.RESPONSIBILITY_CENTER}`,
        name: `row:${FORM_FIELDS_ID.RESPONSIBILITY_CENTER}`,
        label: `${FORM_FIELDS_LABEL.RESPONSIBILITY_CENTER}`,
        selectOptions: options.responsibilityCenterOptions,
        width: '150px',
      },
      {
        id: `row:${FORM_FIELDS_ID.PROJECT_CODE}`,
        label: `${FORM_FIELDS_LABEL.PROJECT_CODE}`,
        placeholder: 'e.g. 001',
      },
      {
        id: `row:${FORM_FIELDS_ID.EMISSION_SCOPE}`,
        name: `row:${FORM_FIELDS_ID.EMISSION_SCOPE}`,
        label: `${FORM_FIELDS_LABEL.EMISSION_SCOPE}`,
        selectOptions: options.emissionScopeOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.SRC_OF_EMISSION_FACTOR_AND_STD_DEV}`,
        name: `row:${FORM_FIELDS_ID.SRC_OF_EMISSION_FACTOR_AND_STD_DEV}`,
        label: `${FORM_FIELDS_LABEL.SRC_OF_EMISSION_FACTOR_AND_STD_DEV}`,
        selectOptions: options.sourceOfStdAndEmissionFactorOptions,
        width: '150px',
      },
      {
        id: `row:${FORM_FIELDS_ID.PRODUCT}`,
        label: `${FORM_FIELDS_LABEL.PRODUCT}`,
        width: '200px',
        type: 'autoComplete',
        source: {
          serviceName: `${SERVICE_NAMES.SUPPLIER_PRODUCT_SERVICE}`,
          display: 'supplierProductName',
          searchFrequency: 3,
        },
      },
      {
        id: `row:${FORM_FIELDS_ID.PRODUCT_QUANTITY}`,
        label: `${FORM_FIELDS_LABEL.PRODUCT_QUANTITY}`,
        type: 'number',
        min: 1,
        step: 'any',
      },
      {
        id: `row:${FORM_FIELDS_ID.PRODUCT_QUANTITY_UNIT}`,
        label: `${FORM_FIELDS_LABEL.PRODUCT_QUANTITY_UNIT}`,
        placeholder: 'e.g. Liter',
      },
      {
        id: `row:${FORM_FIELDS_ID.EMISSION_FACTOR}`,
        label: `${FORM_FIELDS_LABEL.EMISSION_FACTOR}`,
        type: 'number',
        step: 'any',
        min: 0,
      },
      {
        id: `row:${FORM_FIELDS_ID.STD_DEV_EMISSION_FACTOR}`,
        label: `${FORM_FIELDS_LABEL.STD_DEV_EMISSION_FACTOR}`,
        type: 'number',
        step: 'any',
        min: 0,
      },
      {
        id: `row:${FORM_FIELDS_ID.DATA_QUALITY_INDEX}`,
        label: `${FORM_FIELDS_LABEL.DATA_QUALITY_INDEX}`,
        selectOptions: options.dataQualityIndexOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_EMISSION_DEBIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_EMISSION_DEBIT}`,
        type: 'number',
        required: false,
        disabled: true,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_EMISSION_CREDIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_EMISSION_CREDIT}`,
        type: 'number',
        required: false,
        disabled: true,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_STD_DEV_DEBIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_STD_DEV_DEBIT}`,
        type: 'number',
        required: false,
        disabled: true,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_STD_DEV_CREDIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_STD_DEV_CREDIT}`,
        type: 'number',
        required: false,
        disabled: true,
      },
      {
        id: `row:${FORM_FIELDS_ID.ROW_DESCRIPTION}`,
        label: `${FORM_FIELDS_LABEL.ROW_DESCRIPTION}`,
        required: false,
        width: '200px',
      },
    ],
  } as FormFields);

export const getSimplifiedFormFields = (options: SelectOptions) =>
  ({
    headerFields: [
      {
        id: `header:${FORM_FIELDS_ID.TRANSACTION_DATE}`,
        label: `${FORM_FIELDS_LABEL.TRANSACTION_DATE}`,
        type: 'date',
      },
      {
        id: `header:${FORM_FIELDS_ID.TRANSACTION_TYPE}`,
        name: `header:${FORM_FIELDS_ID.TRANSACTION_TYPE}`,
        label: `${FORM_FIELDS_LABEL.TRANSACTION_TYPE}`,
        selectOptions: options.transactionTypeOptions,
      },
      {
        id: `header:${FORM_FIELDS_ID.SUBSIDIARY}`,
        label: `${FORM_FIELDS_LABEL.SUBSIDIARY}`,
        selectOptions: options.generalLedgerOptions,
      },
      {
        id: `header:${FORM_FIELDS_ID.JOURNAL_DESCRIPTION}`,
        label: `${FORM_FIELDS_LABEL.JOURNAL_DESCRIPTION}`,
        required: false,
      },
    ],
    rowFields: [
      {
        id: `row:${FORM_FIELDS_ID.DELETE}`,
        type: 'button',
        width: '25px',
      },
      {
        id: `row:${FORM_FIELDS_ID.TRANSACTION_DIRECTION}`,
        name: `row:${FORM_FIELDS_ID.TRANSACTION_DIRECTION}`,
        label: `${FORM_FIELDS_LABEL.TRANSACTION_DIRECTION}`,
        selectOptions: options.transactionDirectionOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.ACCOUNT_INFORMATION}`,
        name: `row:${FORM_FIELDS_ID.ACCOUNT_INFORMATION}`,
        label: `${FORM_FIELDS_LABEL.ACCOUNT_INFORMATION}`,
        type: 'autoComplete',
        source: {
          serviceName: `${SERVICE_NAMES.ACCOUNT_SERVICE}`,
          display: 'accountNumber',
          searchFrequency: 1,
          filterBy: 'accountNumberStartsWith',
        },
        width: '130px',
      },
      {
        id: `row:${FORM_FIELDS_ID.BRANCH}`,
        name: `row:${FORM_FIELDS_ID.BRANCH}`,
        label: `${FORM_FIELDS_LABEL.BRANCH}`,
        selectOptions: options.branchOptions,
        width: '160px',
      },
      {
        id: `row:${FORM_FIELDS_ID.RESPONSIBILITY_CENTER}`,
        name: `row:${FORM_FIELDS_ID.RESPONSIBILITY_CENTER}`,
        label: `${FORM_FIELDS_LABEL.RESPONSIBILITY_CENTER}`,
        selectOptions: options.responsibilityCenterOptions,
        width: '200px',
      },
      {
        id: `row:${FORM_FIELDS_ID.PROJECT_CODE}`,
        label: `${FORM_FIELDS_LABEL.PROJECT_CODE}`,
        placeholder: 'e.g. 001',
      },
      {
        id: `row:${FORM_FIELDS_ID.EMISSION_SCOPE}`,
        name: `row:${FORM_FIELDS_ID.EMISSION_SCOPE}`,
        label: `${FORM_FIELDS_LABEL.EMISSION_SCOPE}`,
        selectOptions: options.emissionScopeOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.DATA_QUALITY_INDEX}`,
        name: `row:${FORM_FIELDS_ID.DATA_QUALITY_INDEX}`,
        label: `${FORM_FIELDS_LABEL.DATA_QUALITY_INDEX}`,
        selectOptions: options.dataQualityIndexOptions,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_EMISSION_DEBIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_EMISSION_DEBIT}`,
        type: 'number',
        step: 'any',
        required: false,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_EMISSION_CREDIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_EMISSION_CREDIT}`,
        type: 'number',
        step: 'any',
        required: false,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_STD_DEV_DEBIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_STD_DEV_DEBIT}`,
        type: 'number',
        step: 'any',
        required: false,
      },
      {
        id: `row:${FORM_FIELDS_ID.TOTAL_STD_DEV_CREDIT}`,
        label: `${FORM_FIELDS_LABEL.TOTAL_STD_DEV_CREDIT}`,
        type: 'number',
        step: 'any',
        required: false,
      },
      {
        id: `row:${FORM_FIELDS_ID.ROW_DESCRIPTION}`,
        label: `${FORM_FIELDS_LABEL.ROW_DESCRIPTION}`,
        required: false,
        width: '200px',
      },
    ],
  } as FormFields);
