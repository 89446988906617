import { Stack } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const PageLayout = ({ children }: Props) => {
  return (
    <Stack paddingX={6} paddingY={2} spacing={4}>
      {children}
    </Stack>
  );
};
