import { useEffect, useState } from 'react';
import { TextField, Autocomplete, CircularProgress, Box } from '@mui/material';

import {
  type SearchFieldSourceType,
  type OptionType,
  type ServiceType,
  type RequestParams,
  type InputValue,
} from '../../../types';

type Props = {
  id: string;
  onBlur: () => void;
  onChange: (value: InputValue) => void;
  handleSearch: (
    service: ServiceType,
    filterParams: RequestParams,
    sortBy: string
  ) => Promise<OptionType[]>;
  inputValue: InputValue;
  required?: boolean;
  source?: SearchFieldSourceType;
};

export const SearchField = (props: Props) => {
  const {
    id,
    onBlur,
    onChange,
    handleSearch,
    inputValue,
    required = true,
    source,
  } = props;
  const { serviceName, display, searchFrequency, filterBy } = source || {
    serviceName: '',
    display: '',
    searchFrequency: 4,
    filterBy: '',
  };

  const [options, setOptions] = useState<OptionType[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  const [selectedValue, setSelectedValue] = useState<OptionType>(
    inputValue as OptionType
  );

  useEffect(() => {
    if (searchInput === '' || searchInput.length >= searchFrequency) {
      setIsLoading(true);
      const filterParams = filterBy
        ? {
            [filterBy]: searchInput,
          }
        : {};
      handleSearch(serviceName as ServiceType, filterParams, display)
        .then((result) => {
          setOptions(result);
          setIsLoading(false);
        })
        .catch((_) => {
          setOptions([]);
          setIsLoading(false);
        });
    }
  }, [
    searchInput,
    display,
    filterBy,
    searchFrequency,
    serviceName,
    handleSearch,
  ]);

  useEffect(() => {
    setSelectedValue(inputValue as OptionType); // local state for reacting to currency changes
  }, [inputValue]);

  useEffect(() => {
    if (selectedValue && options.length > 0) {
      // this part is to react on currency changes
      const selected = options.find((option) => {
        type OptionKey = keyof typeof option;
        return (
          option[display as OptionKey] === selectedValue[display as OptionKey]
        );
      });
      if (selected) onChange(selected);
      onBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedValue, display, id, searchInput, setSelectedValue]);

  return (
    <Autocomplete
      id={id}
      options={options}
      filterOptions={(x) => x}
      getOptionLabel={(option) => {
        type OptionKey = keyof typeof option;
        return option[display as OptionKey];
      }}
      isOptionEqualToValue={(option, value) => {
        type OptionKey = keyof typeof option;
        const key = display as OptionKey;
        return option[key] === value[key];
      }}
      loading={loading}
      loadingText={
        <Box textAlign={searchInput.length > 0 ? 'center' : 'left'}>
          <CircularProgress size={20} />
        </Box>
      }
      onChange={(_, value) => {
        onChange(value as InputValue);
        setSelectedValue(value as OptionType);
      }}
      onInputChange={(_, inputValue) => {
        setSearchInput(inputValue);
      }}
      onOpen={() => setSearchInput('')}
      value={
        // @ts-ignore
        selectedValue && selectedValue[display] ? selectedValue : null // Terneray to make sure that the default value is not set because the default value will never match with an option which will print out warnings in the console until an actual value is selected.
      }
      renderInput={(params) => (
        <TextField {...params} required={required} placeholder="Search..." />
      )}
    />
  );
};
