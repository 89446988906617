import { type ChangeEvent, useEffect, useState } from 'react';
import { type SelectChangeEvent } from '@mui/material';
import {
  type OptionType,
  type RequestParams,
  type SelectOptionType,
  type ServiceType,
  type SelectInputValue,
  type InputValue,
} from '../../../types';
import { SearchField } from '../SearchField/SearchField';
import { SelectField } from '../SelectField/SelectField';
import { TextField } from '../TextField/TextField';

type Props = {
  id: string;
  name?: string;
  type?: string;
  handleBlur: (
    id: string,
    inputValue: InputValue,
    itemId?: string,
    rowId?: string
  ) => void;
  handleSearch: (
    service: ServiceType,
    filterParams: RequestParams,
    sortBy: string
  ) => Promise<OptionType[]>;
  selectOptions?: SelectOptionType[];
  value: InputValue;
  required?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number | 'any';
  width?: string;
  autofocus?: boolean;
  itemId?: string;
  rowId?: string;
};

export const FormField = (props: Props) => {
  const { type, selectOptions, value, handleBlur, id, itemId, rowId } = props;

  const [inputValue, setInputValue] = useState<SelectInputValue | InputValue>(
    value
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(event.target.value);
  };

  const onSearchChange = (searchValue: InputValue) => {
    setInputValue(searchValue);
  };

  const onBlur = () => {
    if (inputValue !== value) {
      handleBlur(id, inputValue, itemId, rowId);
    }
  };

  const isSearchField = type === 'autoComplete';
  const isSelectField = selectOptions;

  return (
    <>
      {isSearchField ? (
        <SearchField
          onBlur={onBlur}
          onChange={onSearchChange}
          inputValue={inputValue}
          {...props}
        />
      ) : isSelectField ? (
        <SelectField
          inputValue={inputValue as SelectInputValue}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
      ) : (
        <TextField
          inputValue={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
      )}
    </>
  );
};
