export const columns = [
  { name: 'Subsidiary', width: 100 },
  { name: 'Journal Entry ID', width: 100 },
  { name: 'Transaction Date', width: 100 },
  { name: 'Account Number', width: 100 },
  { name: 'Scope', width: 50 },
  { name: 'Branch', width: 150 },
  { name: 'Responsibility Center', width: 150 },
  { name: 'Data Quality Index', width: 150 },
  { name: 'GHG Debit', width: 100 },
  { name: 'GHG Credit', width: 100 },
  { name: 'Std Dev Debit', width: 100 },
  { name: 'Std Dev Credit', width: 100 },
  { name: 'Description', width: 300 },
  { name: 'Transaction ID', width: 300 },
];
