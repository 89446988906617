import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

import { loginRequest } from '../../configs/authConfig';

export enum LoginType {
  REDIRECT = 'redirect',
}

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: LoginType) => {
    if (loginType === LoginType.REDIRECT) {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      onClick={() => handleLogin(LoginType.REDIRECT)}
    >
      Sign in to Emission Accounting
    </Button>
  );
};
